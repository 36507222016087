body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-links:hover {
  color: red !important;
}

.content {
  position: relative;
  /* width: 90%; */
  /* max-width: 400px; */
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  background: rgba(0, 0, 0, 0.85);
  opacity: 1.0;
}

.content-overlay-selected {
  opacity: 1;
}

.content-text {
  opacity: 0;
}

.content-text-selected {
  opacity: 1;
}

.content:hover .content-text {
  opacity: 1;
}

.content-image {
  width: 100%;
}
